import React from 'react';
import '../styles/footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <p>Copyright © 2023 SOK Fruits and Vegetables</p>
    </div>
  );
};

export default Footer;