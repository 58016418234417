import React from 'react'
import '../styles/navbar.css'
import Black from '../Images/BLACK.png'

const Navbar = () => {


  return (
    <div className='navbar_cont'>
        <div className='logo_sec'>
            <img  id='nav_logo' src={Black} alt="nav_logo" />
        </div>
        <div className='title_sec'>
            <div className='title_sec_sub'>
            <h1 className='title_sec1'>SOK</h1>
            <h4 className='title_sec2'>Fruits & Vegetables</h4>
            </div>
            <div className='Nav_links'>
              <a href="/">Home</a>
              {/* <a href="/about">About</a>
              <a href="/contact">Contact</a> */}
            </div>
        </div>
    </div>
  )
}

export default Navbar