import React from 'react'
import Navbar from './Navbar'
import '../styles/home.css'
import bg from '../Images/bgy1.jpg'
import { About } from './About'
import Welcome from './Welcome'
import Ad from './Ad'
import Card from './Card'
import Contact from './Contact'

const Home = () => {
  return (
    <div>
        <Navbar className="navbar"/>
        <div className='home_cont'>
            <img className='home_bg' src={bg} alt="home_bg" />
            <div className='home_screen'></div>
            <div className='home_sub'>
                <h1 className='home_tag1'>"Harvesting Freshness, Nurturing Health"</h1>
                <h4 className='home_tag2'>Your Global Source for Quality Fruits and Vegetables!</h4>
                {/* <button className='home_bttn'>CONTACT US</button> */}
                {/* <div className='homesubpara2wrap'>
        <div className='homesubpara2'>
        <p>Replace Unreliable Freelancers and Expensive Agencies</p> <p>Result Driven Framework</p> <p>Get 100% satisfaction on deliverables</p>
        </div>
        <div className='homesubpara2'>
        <p>Replace Unreliable Freelancers and Expensive Agencies</p> <p>Result Driven Framework</p> <p>Get 100% satisfaction on deliverables</p>
        </div>
        <div className='homesubpara2'>
        <p>Replace Unreliable Freelancers and Expensive Agencies</p> <p>Result Driven Framework</p> <p>Get 100% satisfaction on deliverables</p>
        </div>
        <div className='homesubpara2'>
        <p>Replace Unreliable Freelancers and Expensive Agencies</p> <p>Result Driven Framework</p> <p>Get 100% satisfaction on deliverables</p>
        </div>
        <div className='homesubpara2'>
        <p>Replace Unreliable Freelancers and Expensive Agencies</p> <p>Result Driven Framework</p> <p>Get 100% satisfaction on deliverables</p>
        </div>
        <div className='homesubpara2'>
        <p>Replace Unreliable Freelancers and Expensive Agencies</p> <p>Result Driven Framework</p> <p>Get 100% satisfaction on deliverables</p>
        </div>
        <div className='homesubpara2'>
        <p>Replace Unreliable Freelancers and Expensive Agencies</p> <p>Result Driven Framework</p> <p>Get 100% satisfaction on deliverables</p>
        </div>
    </div> */}
            </div>
   
        </div>
        <About/>
        <Welcome/>
        <Ad/>
        <Card/>
        <Contact/>
    </div>
  )
}

export default Home