import React from 'react'
import '../styles/ad.css'
import ad1 from '../Images/ad1.jpg'
import ad2 from '../Images/ad2.jpg'

const Ad = () => {
  return (
    <div className='ad_cont'>
        <div className='ad_sec1'>
            <div className='sec1_1'></div>
            <div className='sec1_2'></div>
        </div>
        <div className='ad_sec2'>
            <div className='ad_sec2_text_cont'>
            <h1 className='ad_sec2_title'>"Beyond Borders, Trust in Freshness Unleashed."</h1>
            </div>

           <div className='ad_sec_sub'>
           <div className='ad_img1_cont'>
                <img className='ad_img1' src={ad1} alt="" />
            </div>
            <div className='ad_img2_cont'>
                <img className='ad_img2' src={ad2} alt="" />
            </div>
           </div>
           
        </div>
    </div>
  )
}

export default Ad