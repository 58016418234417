import React from 'react'
import '../styles/contact.css'
import pic from '../Images/contact.jpg'
import Footer from './Footer'

const Contact = () => {
  return (
    <div>
         <div className='cont_sec1'>
               
                <div className='cont_desc'>
                    <h1>Contact Us</h1>
                    <p>Your trusted worldwide wholesale distributor 
                        of premium fruits and vegetables. Explore our
                         vast selection of farm-fresh produce sourced
                          from the finest growers across the globe. At 
                          SOK, we are committed to delivering quality, reliability, 
                          and freshness to your doorstep.
                    </p>

                    <form id='form_cont' action="info@sokfruitsandvegetables.com" method="post" encType="text/plain" >
                    <input id='form_mail' type="email" name="Email" placeholder='Email' required/>
                    <input id='form_mess' type="text" name="Message" placeholder='Your Message' required/>
                    <input id='form_bttn' type="submit" name="Submit" value="Submit" />
                    </form>

                </div>
                <div className='cont_img_sec'>
                    <img className='cont_img' src={pic} alt="" />
                </div>
            </div>
            <Footer/>
    </div>
  )
}

export default Contact