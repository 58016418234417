import React from 'react'
import '../styles/welcome.css'
import wel1 from '../Images/welcome1.jpg'

const Welcome = () => {
  return (
    <div className='wel_cont'>
        <div className=''>
            <div className='wel_sec1'>
                <div className='wel_img_sec'>
                    <img className='wel_img' src={wel1} alt="" />
                </div>
                <div className='wel_desc'>
                    <h2>Welcome to SOK</h2>
                    <p>Your trusted worldwide wholesale distributor 
                        of premium fruits and vegetables. Explore our
                         vast selection of farm-fresh produce sourced
                          from the finest growers across the globe. At 
                          SOK, we are committed to delivering quality, reliability, 
                          and freshness to your doorstep.
                    </p>
                </div>
            </div>
            
            <div className='card_sec'>
                <div className='card'>
                    <h2>Sourcing and Quality Assurance</h2>
                    <p>At SOK, quality is our top priority. Learn about our rigorous 
                        sourcing process, ensuring that only the freshest and finest 
                        produce makes its way to your business. Our commitment to quality 
                        assurance is your assurance of excellence.
                    </p>
                </div>
                <div className='card'>
                    <h2>Global Distribution Network</h2>
                    <p>
                    With a widespread network spanning across continents, SOK ensures 
                    seamless delivery to your doorstep. Explore our global distribution 
                    capabilities, allowing us to efficiently serve clients in multiple countries. 
                    Your satisfaction knows no borders with SOK.
                    </p>
                </div>
                <div className='card'>
                    <h2>Sustainability Initiatives</h2>
                    <p>
                    Discover our commitment to sustainability and ethical sourcing. Learn 
                    about our eco-friendly practices and initiatives aimed at reducing our 
                    environmental footprint. At SOK, we believe in nourishing the world responsibly.
                    </p>
                </div>
                <div className='card'>
                    <h2>Global Presence</h2>
                    <p>
                    SOK proudly serves clients in numerous countries, bringing the essence of 
                    freshness to every corner of the globe. Our commitment to excellence and 
                    customer satisfaction transcends borders, making us your go-to partner for 
                    international produce procurement.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Welcome