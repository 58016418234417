import React from 'react'
import '../styles/card.css'
import card2_1 from '../Images/card2_1.jpg'
import card2_2 from '../Images/card2_2.jpg'
import card2_3 from '../Images/card2_3.jpg'

const Card = () => {
  return (
    <div className='card2_cont'>
        <div className='card2_sec1'>
            <div className='card2_sec1_1'></div>
            <div className='card2_sec1_2'></div>
        </div>
        <div className='card2_sec2'>
            <div className='card2'>
                <img className='card2_img' src={card2_1} alt="" />
                <div className='card2_desc'>
                    <h2>Premium Quality</h2>
                    <p>Our produce undergoes rigorous quality checks 
                        to ensure it meets the highest standards. We believe 
                        in delivering nothing but the freshest and finest to 
                        our clients.
                    </p>
                </div>
            </div>

            <div className='card2'>
                <img className='card2_img' src={card2_2} alt="" />
                <div className='card2_desc'>
                    <h2>Global Network</h2>
                    <p>
                    With an extensive network of suppliers and partners worldwide, 
                    we guarantee a diverse selection of fruits and vegetables all 
                    year round. From tropical delights to seasonal staples, SOK has it all.
                    </p>
                </div>
            </div>
            <div className='card2'>
                <img className='card2_img' src={card2_3} alt="" />
                <div className='card2_desc'>
                    <h2>Reliable Logistics</h2>
                    <p>Timely delivery is crucial in the wholesale business. With our efficient 
                        logistics network, we ensure that your orders reach you in optimal 
                        condition and on schedule.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Card