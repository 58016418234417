import React from 'react'
import '../styles/about.css'
import about1 from '../Images/about1.jpg'
import about2 from '../Images/about2.jpg'
import insure from '../Images/insure.jpg'

export const About = () => {
  return (
    <div className='about_cont'>
        <div className='about_sec'>
            <div className='abot_img_sec'>
                <img className='about_img' src={about1} alt="" />
            </div>
            <div className='about_desc'>
                <h2>About Us</h2>
                <p className='about_desc_para'>Discover the SOK difference! 
                    With decades of experience, 
                    we have established ourselves 
                    as a leading player in the international
                    wholesale fruits and vegetables market. 
                    Learn about our passion for quality, 
                    sustainability, and our unwavering dedication 
                    to customer satisfaction.
                </p>
            </div>
        </div>
        
        <div className='about_sec'>
            <div className='abot_img_sec'>
                <img className='about_img' src={insure} alt="" />
            </div>
            <div className='about_desc'>
                <h2>Globally insured</h2>
                <p className='about_desc_para'>Our products and services are globally insured with  COFACE AND  CGIC Insurance
                </p>
            </div>
        </div>

        <div className='about_sec'>
            <div className='abot_img_sec'>
                <img className='about_img' src={about2} alt="" />
            </div>
            <div className='about_desc'>
                <h2>Our Products</h2>
                <p className='about_desc_para'>Browse through our extensive 
                catalog showcasing a diverse range of fruits and vegetables.
                 From exotic tropical fruits to crisp, seasonal vegetables,
                  SOK offers a comprehensive selection to meet the needs of 
                  our global clientele. Explore the flavors of nature with SOK.
                </p>
            </div>
        </div>
    </div>
  )
}
